import React from "react"

import NavBar from "./NavBar"

const DefaultHeader = () => {
    return (
        <React.Fragment>
            <NavBar />
        </React.Fragment>
    )
}

export default DefaultHeader
